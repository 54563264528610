import React, { useEffect, useState } from "react";
import { MDBBtn, MDBContainer, MDBInput, MDBSpinner } from "mdb-react-ui-kit";
import Select from "react-select";
import Lognavbar from "../Components/lognavbar";
import { Link } from "react-router-dom";
import Footer from "./footer";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function AddJobs() {
  useEffect(() => {
    document.body.style.fontFamily = "bahnschrift";
    document.body.style.backgroundColor = "#000000";
  }, []);

  const initialForm = {
    job_name: "",
    job_description: "",
    job_detail: "",
    job_price: "",
    job_image: null,
    seekersId: Cookies.get("S_Id"),
  };

  const [formData, setFormData] = useState(initialForm);
  const [submit, setSubmit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "job_image") {
      const file = files[0];

      setFormData((prevFormData) => ({
        ...prevFormData,
        job_image: file,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  async function handleAddJob(e) {
    e.preventDefault();
    console.log(formData);
    try {
      setSubmit(true);
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/seekers/profile/update/addjob`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const responseData = response.data;
      if (responseData.message === "success") {
      
      }
      setFormData(initialForm);
      setSubmit(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div>
      <Lognavbar />
      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <ToastContainer />
        <div className="m-5" style={{ width: "400px" }}>
          <h3 style={{ color: "white", marginTop: "5%" }}>Add Job</h3>
          <hr style={{ color: "white" }} />
          <form onSubmit={handleAddJob}>
            <div className="mb-4" style={{ marginTop: "10px" }}>
              <label
                htmlFor="job_name"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Job Name
              </label>
              <MDBInput
                size="lg"
                type="text"
                style={{ borderRadius: "0" }}
                name="job_name"
                required
                value={formData.job_name}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4" style={{ marginTop: "10px" }}>
              <label
                htmlFor="job_description"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Job Description
              </label>
              <MDBInput
                size="lg"
                type="text"
                style={{ borderRadius: "0" }}
                name="job_description"
                value={formData.job_description}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4" style={{ marginTop: "10px" }}>
              <label
                htmlFor="job_detail"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Job Detail
              </label>
              <MDBInput
                size="lg"
                type="text"
                style={{ borderRadius: "0" }}
                name="job_detail"
                value={formData.job_detail}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4" style={{ marginTop: "10px" }}>
              <label
                htmlFor="job_price"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Job Price
              </label>
              <MDBInput
                size="lg"
                type="number"
                style={{ borderRadius: "0" }}
                name="job_price"
                value={formData.job_price}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4" style={{ marginTop: "10px" }}>
              <label
                htmlFor="job_image"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Job Image
              </label>
              <MDBInput
                size="lg"
                type="file"
                style={{ borderRadius: "0" }}
                name="job_image"
                onChange={handleInputChange}
              />
            </div>

            <MDBBtn
              className="mb-4 w-100 gradient-custom-4"
              size="lg"
              style={{
                borderRadius: "0",
                color: "black",
                backgroundColor: "white",
              }}
              type="submit"
            >
              {submit ? <MDBSpinner /> : <span>Add Job</span>}
            </MDBBtn>
          </form>
        </div>
      </MDBContainer>

      <Footer />
    </div>
  );
}
