import React, { useEffect, useState, useRef } from "react";
import Lognavbar from "../Components/lognavbar";
import { MDBBtn, MDBRow, MDBCol } from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import {Link} from 'react-router-dom';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [ws, setWs] = useState(null);
  const sentMessages = new Map();
  const messagesEndRef = useRef(null);
  const [viewersmessages,setallviewersmessages]=useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [name,setName]=useState("");
  const [profilepic,setProfilepic]=useState("");

  const {viewersId}=useParams();
  const {jobId}=useParams();
  const seekersId=localStorage.getItem("S_ID");

  const generateMessageId = () => Math.random().toString(36).substr(2, 9);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const fetchData = async () => {
      await GetChatAllViewers();
      if (viewersId && seekersId) {
        await GetReviews(viewersId, seekersId,jobId);
      }
      await GetProfile();
    };

    const socket = new WebSocket('wss://vast-gamy-green.glitch.me');

    setWs(socket);

    socket.onopen = () => {
      console.log('WebSocket connected');
    };

    socket.onmessage = (event) => {
      const receivedMessage = JSON.parse(event.data);
      setMessages((prevMessages) => {
        if (receivedMessage.job_id === jobId) {
            return [...prevMessages, receivedMessage];
        }
        return prevMessages;
      });
      const messageId = receivedMessage.id;

      if (sentMessages.has(messageId)) {
        const message = sentMessages.get(messageId);
        message.status = 'read';
        sentMessages.set(messageId, message);
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    fetchData();

    document.body.style.backgroundColor = '#000000';

    return () => {
      socket.close();
    };
  }, [seekersId]);
  
  async function GetProfile() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/profile/get?Id=${viewersId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = response.data.data || [];

      if (responseData.length > 0) {
        const firstProfile = responseData[0];
        setName(firstProfile.firstname);
        setProfilepic(firstProfile.profilePicture);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const GetReviews = async (viewersId, seekersId, jobId) => {

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            viewersId,
            seekersId,
            jobId
          },
        }
      );
      const responseData = response.data.data;
      setMessages(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };


  async function GetChatAllViewers() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat/GetChatAllViewers`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            seekersId: Cookies.get("S_Id"),
          },
        }
      );
      const responseData = response.data.data;
      setallviewersmessages(response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const sendMessage = () => {
    if (ws && ws.readyState === 1 && input!="") {
      const messageId = generateMessageId();
      const userImage = Cookies.get("S_Picture") && Cookies.get("S_Picture").startsWith("https://api.asareviewing.com")
        ? Cookies.get("S_Picture")
        : `${process.env.REACT_APP_BASE_URL}/files/${Cookies.get("S_Picture")}`;
  
      const fileInput = document.getElementById("fileInput");
      const file = fileInput.files[0];
  
      const uploadFileToCloudinary = (file) => {
        document.getElementById("filetext").style.display="block";
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'wukncq9d');
  
        return axios.post('https://api.cloudinary.com/v1_1/dge3lt4u6/auto/upload', formData)
        .then(response => {
          console.log('File uploaded successfully:', response.data);
          return response.data.url;
        })
        .catch(error => {
          console.error('Error uploading to Cloudinary:', error.response ? error.response.data : error.message);
          throw error;
        });
      };
  
      const sendMessageToWebSocket = (fileUrl = null) => {
        const currentDateTime = new Date().toISOString();
    
        let messageObject = {
          id: messageId,
          userId: Cookies.get("S_Id"),
          seekersId: Cookies.get("S_Id"),
          viewersId: viewersId,
          seekerEmail: Cookies.get("S_email"),
          userImage: userImage,
          recieverName: "Seeker",
          jobId: jobId,
          chatId: "1",
          text: input,
          file: fileUrl ? { url: fileUrl } : null,
          timestamp: currentDateTime,
        };
      
        const messageString = JSON.stringify(messageObject);
        ws.send(messageString);
      
        sentMessages.set(messageId, {
          text: input,
          status: "not read",
          file: fileUrl ? { url: fileUrl } : null,
          timestamp: currentDateTime,
        });
      
        setInput("");
        document.getElementById("filetext").style.display = "none";
        document.getElementById("fileInput").value = null;
      };
      
      if (file) {
        uploadFileToCloudinary(file)
          .then(fileUrl => {
            sendMessageToWebSocket(fileUrl);
          })
          .catch(error => {
            console.error('Error uploading file:', error);
          });
      } else {
        sendMessageToWebSocket();
      }
      } else {
        console.log("WebSocket not connected");
      }
    };
      
  const index = messages&&messages.findIndex(message => message.recieverName === "Viewer");

  const imageUrl = index !== -1 ? messages[index].userImage : "";

  function convertToUKTime(dateString) {
    const date = new Date(dateString);
  
    const options = {
      timeZone: 'Europe/London',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
  
    const ukTimeString = date.toLocaleString('en-GB', options);
  
    return ukTimeString;
  }

  return (
    <section>
      <Lognavbar />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
            {!isMobile?(
              <h5
                className="font-weight-bold mb-3 text-center text-lg-start"
                style={{ color: "white" }}
              >
                Contacts
              </h5>
            ):(
              ""
            )}
            <div className="card" style={{ backgroundColor: "#1a2229" }}>
              {!isMobile?(
                <div className="card-body">
                <ul className="list-unstyled mb-0">
                {viewersmessages &&
                    viewersmessages.map((viewer, index) => {
                      return (
                        <li
                          className="p-2 border-bottom"
                          style={{ backgroundColor: "#000000" }}
                        >
                          <a
                            key={viewer.viewersId}
                            href={`/chat/${viewer.viewersId}/${viewer.job_id}`}
                            className="d-flex justify-content-between"
                          >
                            <div className="d-flex flex-row">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/files/${viewer.profilePicture}`}
                                alt="avatar"
                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                width="60"
                                height="60"
                              />
                              <div
                                className="pt-1"
                                style={{ textAlign: "left" }}
                              >
                                <p
                                  className="fw-bold mb-0"
                                  style={{ color: "#ff4e00" }}
                                >
                                  {viewer.firstname} {viewer.lastname} <span style={{color:"white",fontSize:"12px",marginLeft:"7px"}}>(Ref No: {viewer.job_id+1000})</span>
                                </p>
                                <p className="small text-muted">
                                  {viewer.message}
                                </p>
                              </div>
                            </div>
                          </a>
                        </li>
                      );
                    })}
                
                </ul>
              </div>
              ):(
                <div style={{textAlign:"left",padding:"10px"}}>
                  <Link style={{color:"white",marginLeft:"10px"}} to="/chat"><i className="fa fa-arrow-left" style={{color:"#ff4e00",marginRight:"10px"}}></i>Back to Contacts</Link>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-7 col-xl-8">
            <ul className="list-unstyled">
              <li
                className="d-flex justify-content-between mb-4"
                style={{
                  backgroundColor: "#1a2229",
                  height: "400px",
                  overflowY: "auto",
                }}
              >
                <div
                  className="card"
                  style={{ backgroundColor: "#1a2229", width: "100%" }}
                >
                  <div className="card-header d-flex p-3">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/files/${profilepic}`}
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    />
                    <div style={{ marginLeft: "12px" }}>
                      <p className="fw-bold mb-0" style={{ color: "#ff4e00" }}>
                      {name} <span style={{color:"white",fontSize:"12px",marginLeft:"7px"}}>(Ref No: {parseInt(jobId)+1000})</span>
                       </p>
                      
                    </div>
                  </div>
                  <div class="card-body">
                    {messages &&
                      messages.map((item, index) => (
                        <MDBRow>
                          <MDBCol md={8}>
                          {item.recieverName !== "Seeker" ? (
                              <div
                                class="mb-0"
                                style={{
                                  textAlign: "left",
                                  justifyContent: "flex-start",
                                  display: "flex",
                                }}
                              >
                                <img
                                  src={item.userImage}
                                  alt=""
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                  <p style={{ color: "#ff4e00" }}>
                                  {
                                    item.firstname && item.lastname 
                                      ? item.firstname + " " + item.lastname 
                                      : "Seeker"
                                  }
                                  <span style={{color:"white",fontSize:"10px",marginLeft:"10px"}}>{convertToUKTime(item.timestamp)}</span>
                                  </p>
                                  <p
                                    style={{
                                      color: "white",
                                      marginTop: "-17px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item.text}
                                  </p>
                                  {item.file ? (
                                    <div
                                      style={{
                                        width: "200px",
                                        height: "50px",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        border: "1px solid white",
                                        marginTop: "-8px",
                                        display: "flex",
                                      }}
                                    >
                                      <a
                                        href={`${item.file}`}
                                        target="_blank"
                                      >
                                        <i
                                          className="fa fa-file"
                                          style={{
                                            marginTop: "4px",
                                            color: "#ff4e00",
                                          }}
                                        ></i>{" "}
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            marginTop: "4px",
                                            color: "white",
                                            fontSize: "12px",
                                          }}
                                        >
                                          File
                                        </span>
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </MDBCol>
                          <MDBCol md={4}>
                          {item.recieverName==="Seeker" ? (
                              <div
                                class="mb-0"
                                style={{ textAlign: "left", display: "flex",justifyContent:"flex-start" }}
                              >
                                <img
                                  src={item.userImage}
                                  alt=""
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                  <p style={{ color: "#ff4e00" }}>
                                    {item.seekersId == Cookies.get("S_Id")
                                      ? "ME"
                                      : item.recieverName}
                                    <span style={{color:"white",fontSize:"10px",marginLeft:"10px"}}>{convertToUKTime(item.timestamp)}</span>
                                  </p>
                                  <p
                                    style={{
                                      color: "white",
                                      marginTop: "-17px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item.text}
                                  </p>
                                  {item.file ? (
                                    <div
                                      style={{
                                        width: "200px",
                                        height: "50px",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        border: "1px solid white",
                                        marginTop: "-8px",
                                        display: "flex",
                                      }}
                                    >
                                      <a
                                        href={`${item.file}`}
                                        target="_blank"
                                      >
                                        <i
                                          className="fa fa-file"
                                          style={{
                                            marginTop: "4px",
                                            color: "#ff4e00",
                                          }}
                                        ></i>{" "}
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            marginTop: "4px",
                                            color: "white",
                                            fontSize: "12px",
                                          }}
                                        >
                                          File
                                        </span>
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div ref={messagesEndRef} />
                          </MDBCol>
                        </MDBRow>
                      ))}
                  </div>
                </div>
              </li>
              <div style={{ marginTop: "-17px" }}>
                <li>
                  <div data-mdb-input-init className="form-outline">
                    <textarea
                      className="form-control"
                      id="textAreaExample2"
                      rows="4"
                      style={{
                        backgroundColor: "#1a2229",
                        color: "white",
                        border: "1px solid white",
                      }}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                    ></textarea>
                    <label className="form-label" htmlFor="textAreaExample2">
                      Message
                    </label>
                  </div>
                  <div
                    className="form-outline"
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                  >
                    <input
                      className="form-control"
                      id="fileInput"
                      type="file"
                      style={{
                        backgroundColor: "#1a2229",
                        color: "white",
                        border: "1px solid white",
                      }}
                    />
                  </div>
                </li>
                <p style={{color:"white",display:"none"}} id="filetext">File is Uploading....</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDBBtn
                    style={{ color: "white",visibility:"hidden", backgroundColor: "#ff4e00" }}
                  >
                    Job Completed
                  </MDBBtn>
                  <MDBBtn
                    style={{ color: "white", backgroundColor: "#ff4e00" }}
                    onClick={sendMessage}
                  >
                    <i
                      className="fa fa-send"
                      style={{ marginRight: "10px" }}
                    ></i>
                    Send
                  </MDBBtn>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chat;
