import React, { useState, useEffect } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBBtn
} from "mdb-react-ui-kit";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

export default function Lognavbar() {
  const [openBasic, setOpenBasic] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    Cookies.remove('S_email');
    Cookies.remove('S_token');
    Cookies.remove('S_Picture');
    Cookies.remove('S_Id');
    localStorage.removeItem("ID")
    window.location.href = "https://asareviewing.com";
  };

  const [prices, setallprices] = useState("");

  const fetchdata = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/admin/getPrices`
    );
    setallprices(response.data);
  };

  useEffect(() => {
    fetchdata();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const basicViewingPrice =
    prices && prices.find((p) => p.PackageName === "Basic Viewing");
  const ProViewing =
    prices && prices.find((p) => p.PackageName === "Pro Viewing");
  const Property =
    prices && prices.find((p) => p.PackageName === "Property Search");

  return (
    <MDBNavbar expand='lg' style={{ position: "sticky", top: "0", backgroundColor: "black", zIndex: "9999" }}>
      <MDBContainer fluid>
        <MDBNavbarBrand href='#'>
          <a href="https://asareviewing.com">
            <div style={{ textAlign: 'center', alignContent: "center", alignItems: "center" }}>
              <img src="https://www.asareviewing.com/Assets/logo.png" alt="" className="img-fluid logomain" />
            </div>
          </a>
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenBasic(!openBasic)}
        >
          <MDBIcon icon='bars' fas style={{ color: "white" }} />
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openBasic} style={{ color: "white" }}>
          <MDBNavbarNav className={isMobile ? 'justify-content-start align-items-start' : 'justify-content-end'} style={{ marginTop: "20px",marginLeft:"10px" }}>

            <MDBNavbarItem>
              <MDBNavbarLink active aria-current='page' href='#' style={{ marginRight: "10px", marginTop: "5px" }}>
                <Link to="/home" className="links">Home</Link>
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current='page' href=''>
                <Link to="/profile" className="links">Profile</Link>
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current='page' href=''>
                <Link to="/chat" className="links">Chat</Link>
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current='page' href=''>
                <Link to="/all-packages" className="links">Packages</Link>
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBDropdown show={isOpen} onClick={toggleDropdown} style={{ color: "white", marginTop: "-4px" }}>
                <MDBDropdownToggle
                  tag="a"
                  className="nav-link d-flex align-items-center"
                  href="#"
                  style={{ color: "white" }}
                >
                  <img
                    src={Cookies.get('S_Picture') && Cookies.get('S_Picture').startsWith('https://api.asareviewing.com') ? Cookies.get('S_Picture') : `${process.env.REACT_APP_BASE_URL}/files/${Cookies.get('S_Picture')}`}
                    className="rounded-circle"
                    height="30"
                    width="30"
                    alt="Avatar"
                    loading="lazy"
                  />
                </MDBDropdownToggle>
                <MDBDropdownMenu style={{ padding: "10px" }}>
                  <MDBDropdownItem>
                    <Link onClick={logout} to="/">Logout</Link>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
