import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import axios from 'axios';

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = '#000000';
    
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
     const response= await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Admin/RequestResetPasswordseeker`, { email });

     if(response.data.message==="invalid")
     {
      alert("Seeker does not exist.");

     }
     else{
     alert("Password reset link has been sent to your email.");
     window.location.href="/";

     }
    } catch (error) {
      console.error('Error:', error.message);
      alert("Some Error Occured");
    }
    setLoading(false);
  };

  return (
    <MDBContainer className="mt-5 ">


      <img className='img-fluid logoforgot' src="Assets/logo.png" alt="" />
      <MDBRow className="justify-content-center mt-5">
        <MDBCol md="6">
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center mb-4">
              <h2 style={{color:"#FF4E00"}} className="text-center ">Request Password Reset</h2>
            </div>
            <MDBInput
              type="email"
              label="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              // placeholder='Enter Your Email'


            />
            <div className="d-grid gap-2 mt-4">
              <MDBBtn color="white"
          style={{outline:"0", borderRadius: "0" ,border:0, background: "transparent",color:"#FF4E00" }} type="submit" disabled={loading}>
                {loading ? 'Sending...' : 'Request Password Reset'}
              </MDBBtn>
            </div>
          </form>


        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default RequestPasswordReset;
