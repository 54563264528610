import React, { useEffect } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBBtn, MDBContainer, MDBInput, MDBSpinner } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import axios from "axios";
export default function Login() {
  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setSubmit(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/asare/login`,
        {
          email: email,
          password: password,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data;
      console.log("responseData", responseData);
      if (responseData.message == "success") {
        Cookies.set("S_email", responseData.email, { expires: 1 });
        Cookies.set("S_token", responseData.token, { expires: 1 });
        Cookies.set("S_name", responseData.firstName, { expires: 1 });
        Cookies.set("S_Picture", responseData.image, { expires: 1 });
        Cookies.set("S_Id", responseData.Id, { expires: 1 });

        localStorage.setItem("ID", responseData.Id);
        localStorage.setItem("S_ID", responseData.Id);

        window.location.href = "/all-packages";
      } else if (responseData.message == "failure") {
        errorNotify();
      }
      setSubmit(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const SuccessNotify = () => {
    toast.success("Login Successful", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorNotify = () => {
    toast.error("Invalid Login", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const sendlogin = () => {
    window.location.href = "https://api.asareviewing.com/auth/google";
  };

  const handlefacebook=()=>{
    window.location.href = "https://api.asareviewing.com/auth/facebook";

  }

  function generateUniqueId() {
    return Math.floor(10000 + Math.random() * 90000).toString();
}

  const handleGoogleCallback = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get("client_Id");
    const client_d = urlParams.get("client_d");

    const mainid=urlParams.get("mainid");
    const decodedPicture = atob(client_d);


    console.log("This is the Main Id",mainid);

    
    if (clientId) {

      const uniqueId = generateUniqueId();
      Cookies.set("S_token", clientId);
      Cookies.set("S_Picture", decodedPicture);
      Cookies.set("S_Id", mainid, { expires: 1 });
      localStorage.setItem("ID",mainid);

      window.location.href = "/home";

    } else {
      console.error("client_Id not found in the URL");
    }
  };

  useEffect(() => {
    handleGoogleCallback();
  }, []);

  const reset=()=>
  {
    window.location.href="/resetpassword";
  }


  return (
    <div>
      <ToastContainer />

      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center bg-image"
        style={{ minHeight: "100vh" }}
      >
        <div className="m-5" style={{ width: "400px" }}>
        <a href="https://asareviewing.com"><img src="Assets/logo.png" alt="" className="img-fluid logomain1" srcset="" /></a>

          <h3 style={{ color: "white", marginTop: "5%" }}>LOGIN / REGISTER NOW</h3>
          <MDBBtn
            onClick={sendlogin}
            className="mb-4 w-100 gradient-custom-4"
            size="lg"
            style={{
              borderRadius: "0",
              color: "white",
              backgroundColor: "black",
              border: "1px solid white",
              marginTop: "20px",
            }}
          >
            <img
              src="../Assets/google.png"
              style={{ width: "20px", marginRight: "20px", marginTop: "-4px" }}
            ></img>
            Sign In with Google
          </MDBBtn>
          <MDBBtn

          onClick={handlefacebook}
            className="mb-4 w-100 gradient-custom-4"
            size="lg"
            style={{
              borderRadius: "0",
              color: "white",
              backgroundColor: "black",
              border: "1px solid white",
              marginTop: "-10px",
            }}
          >
            <img
              src="../Assets/facebook.png"
              style={{ width: "25px", marginRight: "15px", marginTop: "-4px" }}
            ></img>
            Sign In with Facebook
          </MDBBtn>

          <hr style={{ color: "white" }} />
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Email
              </label>
              <MDBInput
                id="email"
                type="email"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Password
              </label>
              <MDBInput
                id="password"
                type="password"
                size="lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <MDBBtn
              type="submit"
              className="mb-4 w-100 gradient-custom-4"
              size="lg"
              style={{
                borderRadius: "0",
                color: "black",
                backgroundColor: "white",
              }}
            >
              {submit ? <MDBSpinner></MDBSpinner> : <span>Submit</span>}
            </MDBBtn>
          </form>

          <p style={{color:"white"}}>Don't have an Account? <a href="https://asareviewing.com/register" style={{color:"#ff4e00"}}>Register</a></p>
          <MDBBtn onClick={reset}
          className="mb-4"
          size="sm"

          color="white"
          style={{outline:"0", borderRadius: "0" ,border:0, background: "transparent",color:"#FF4E00" }}
        >
          Forgot Password ?
        </MDBBtn>
        </div>
      </MDBContainer>
    </div>
  );
}
