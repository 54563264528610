import './App.css';
import {Switch,Route} from 'react-router-dom';
import Home from './Pages/Home';
import Profile from './Pages/profile';
import Chat from './Pages/chat';
import History from './Pages/jobhistory';
import JobAssigned from './Pages/jobassigned';
import CurrentJobs from './Pages/currentjobs';
import Earnings from './Pages/earning';
import Details from './Pages/details';
import Login from './Pages/login';
import AddJobs from './Pages/AddJobs';
import Subscribe from './Pages/packageForm';
import PropertySearch from './Pages/propertySearch';

import ForgotPassword from "./Pages/ForgotPassword"
import ResetPassword from "./Pages/ResetPassword"
import AllPackages from './Pages/allpackages';
import AllChat from './Pages/allchat';
import Cancel from './Pages/cancel';

function App() {
  return (
    <div className="App">
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/addjob" component={AddJobs}/>

          <Route exact path="/profile" component={Profile}/>
          <Route exact path="/chat/:viewersId/:jobId" component={Chat}/>
          <Route exact path="/job-history" component={History}/>
          <Route exact path="/job-assigned" component={JobAssigned}/>
          <Route exact path="/current-jobs" component={CurrentJobs}/>
          <Route exact path="/earnings" component={Earnings}/>
          <Route exact path="/job-details" component={Details}/>
          <Route exact path="/all-packages" component={AllPackages}/>
          <Route exact path="/chat" component={AllChat}/>
          <Route exact path="/cancel" component={Cancel}/>

          <Route exact path="/Subscribe" component={Subscribe}/>
          <Route exact path="/property-search" component={PropertySearch}/>

          <Route exact path="/resetpassword" component={ForgotPassword}/>
        <Route exact path="/reset-password/:token" component={ResetPassword}/>
        </Switch>
    </div>
  );
}

export default App;
