import React, { useEffect, useState } from "react";
import { MDBBtn, MDBContainer, MDBInput, MDBSpinner } from "mdb-react-ui-kit";
import axios from "axios";
import Lognavbar from "../Components/lognavbar";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

import Auth from "./auth";

export default function Profile() {
  const [profile, setProfile] = useState([]);
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);

  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
    GetProfile();
  }, []);

  const initialForm = {
    profilePicture: null,
    email: "",
    password: "",
    seekersId:Cookies.get("S_Id")
  };

  const [formData, setFormData] = useState(initialForm);
  const [submit, setSubmit] = useState(false);
  const [submitImage, setSubmitImage] = useState(false);
  const [error, setError] = useState("");

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#000000",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#007BFF" : "#FFFFFF",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
  };

  async function GetProfile() {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/seekers/profile/get?Id=${Cookies.get("S_Id")}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data.data;
      formData.profilePicture = responseData[0].profilePicture;
      Cookies.set("S_Picture", responseData[0].profilePicture);
      formData.email = responseData[0].email;
      setProfile(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function handleImage(e) {
    e.preventDefault();

 

    console.log(formData);
    try {
      setSubmitImage(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/seekers/profile/update/updateprofilepic`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data)
;      const responseData = response.data;
      if (responseData.message == "success") {
        window.location.href = "/profile";
      } else if (responseData.message == "Error_Email") {
        // errorNotify();
      }
      setSubmitImage(false);

      GetProfile();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function handleProfile(e) {
    try {
      setSubmit(true);
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/seekers/profile/update/updateonlytwo`,
        {
          ...formData,
          seekersId: Cookies.get("S_Id"),
        }
      );
      const responseData = response.data;
      if (responseData.message == "success") {
        NotifyNew();
        setSubmit(false);
        GetProfile();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profilePicture") {
      const file = files[0];

      setFormData((prevFormData) => ({
        ...prevFormData,
        profilePicture: file,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const NotifyNew = () => {
    toast.success("Profile Update", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <div>

      <Auth/>
      <Lognavbar />
      <ToastContainer />
      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center bg-image"
        style={{ minHeight: "100vh" }}
      >
        <div className="mask gradient-custom-3"></div>
        <div className="m-5" style={{ width: "400px" }}>
          <h3 style={{ color: "white", marginTop: "5%" }}>Profile Settings</h3>
          <center>
            <div style={{ display: "flex" }}>
              <img
                src={
                  Cookies.get("S_Picture") &&
                  Cookies.get("S_Picture").startsWith("https://")
                    ? Cookies.get("S_Picture")
                    : `${process.env.REACT_APP_BASE_URL}/files/${Cookies.get(
                        "S_Picture"
                      )}`
                }
                alt=""
                style={{ width: "100px", borderRadius: "50%", height: "100px" }}
              />
              <MDBBtn
                style={{
                  color: "white",
                  backgroundColor: "#ff4e00",
                  height: "38px",
                  marginTop: "25px",
                  marginLeft: "20px",
                }}
                onClick={toggleOpen}
              >
                Change Image
              </MDBBtn>
            </div>
          </center>

          <div className="mb-4" style={{ marginTop: "20px" }}>
            <label
              htmlFor="form1"
              style={{ textAlign: "left", display: "block", color: "white" }}
            >
              Email
            </label>
            <MDBInput
              size="lg"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="form1"
              style={{ textAlign: "left", display: "block", color: "white" }}
            >
              Password
            </label>
            <MDBInput
              size="lg"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>

          <MDBBtn
            className="mb-4 w-100 gradient-custom-4"
            size="lg"
            style={{
              borderRadius: "0",
              color: "black",
              backgroundColor: "white",
            }}
            onClick={handleProfile}
          >
            {submit ? <MDBSpinner></MDBSpinner> : <span>Update Profile</span>}
          </MDBBtn>
        </div>
      </MDBContainer>

      <MDBModal
        open={basicModal}
        onClose={() => setBasicModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog centered>
          <MDBModalContent style={{ backgroundColor: "#1a2229" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#ff4e00" }}>
                Change Photo
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="mb-4" style={{ marginTop: "20px" }}>
                <label
                  htmlFor="form1"
                  style={{
                    textAlign: "left",
                    display: "block",
                    color: "white",
                  }}
                >
                  Profile Picture
                </label>
                <MDBInput
                  size="lg"
                  type="file"
                  name="profilePicture"
                  onChange={handleInputChange}
                />
              </div>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                style={{ color: "white", backgroundColor: "#ff4e00" }}
                onClick={handleImage}
              >
                {submitImage ? <MDBSpinner></MDBSpinner> : <span>Update</span>}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}
