import React, { useEffect, useState, useRef } from "react";
import Lognavbar from "../Components/lognavbar";
import { MDBBtn, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";

const AllChat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [ws, setWs] = useState(null);
  const sentMessages = new Map();
  const messagesEndRef = useRef(null);
  const [viewersmessages,setallviewersmessages]=useState('');

  const {viewersId}=useParams();
  const seekersId=localStorage.getItem("S_ID");

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const generateMessageId = () => Math.random().toString(36).substr(2, 9);

  useEffect(() => {
    GetChatAllViewers();
    GetReviews(viewersId,seekersId);
    document.body.style.backgroundColor = "#000000";
    const newWs = new WebSocket("wss://socket.asareviewing.com:3000");
    console.log(newWs);
    setWs(newWs);

    newWs.onopen = () => {
      console.log("WebSocket connected");
    };

    newWs.onmessage = (messageEvent) => {
      const receivedMessage = JSON.parse(messageEvent.data);
      setMessages((prevMessages) => [...prevMessages, receivedMessage]);
      scrollToBottom();
      const messageId = receivedMessage.id;

      if (sentMessages.has(messageId)) {
        const message = sentMessages.get(messageId);
        message.status = "read";
        sentMessages.set(messageId, message);
      }
    };

    newWs.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      newWs.close();
    };
  }, []);

  const GetReviews = async (viewersId, seekersId) => {

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            viewersId,
            seekersId,
          },
        }
      );
      const responseData = response.data.data;
      setMessages(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };


  async function GetChatAllViewers() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat/GetChatAllViewers`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            seekersId: Cookies.get("S_Id"),
          },
        }
      );
      const responseData = response.data.data;
      console.log(responseData);
      setallviewersmessages(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const sendMessage = () => {
    if (ws && ws.readyState == 1) {
      const messageId = generateMessageId();
      const userImage =
        Cookies.get("S_Picture") &&
        Cookies.get("S_Picture").startsWith("https://api.asareviewing.com")
          ? Cookies.get("S_Picture")
          : `${process.env.REACT_APP_BASE_URL}/files/${Cookies.get(
              "S_Picture"
            )}`;
      const fileInput = document.getElementById("fileInput");
      const file = fileInput.files[0];
      const reader = new FileReader();

      reader.onload = function (event) {
        const fileData = event.target.result;
        let messageObject = {
          id: messageId,
          userId: Cookies.get("S_Id"),
          seekersId: Cookies.get("S_Id"),
          viewersId:viewersId,
          seekerEmail:Cookies.get("S_email"),

          userImage: userImage,
          recieverName: "Seeker",
          chatId: "1",
          text: input,
          file: file
            ? {
                name: file.name,
                type: file.type,
                size: file.size,
                data: fileData,
              }
            : null,
        };

        const messageString = JSON.stringify(messageObject);
        ws.send(messageString);

        sentMessages.set(messageId, {
          text: input,
          status: "not read",
          file: file,
        });

        setInput("");
      };

      reader.onerror = function (event) {
        console.error("File reading error:", event.target.error);
      };

      if (file) {
        reader.readAsDataURL(file); // Read the file as data URL
      } else {
        // If no file is selected, send the message without file details
        reader.onload({ target: { result: "" } });
      }
    } else {
      console.log("WebSocket not connected");
    }
  };


  const index = messages&&messages.findIndex(message => message.recieverName === "Viewer");

const imageUrl = index !== -1 ? messages[index].userImage : "";

  return (
    <section>
      <Lognavbar />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
            <h5
              className="font-weight-bold mb-3 text-center text-lg-start"
              style={{ color: "white" }}
            >
              Contacts
            </h5>
            <div className="card" style={{ backgroundColor: "#1a2229" }}>
              <div className="card-body">
                <ul className="list-unstyled mb-0">

                {viewersmessages &&
                    viewersmessages.map((viewer, index) => {
                      return (
                        <li
                          className="p-2 border-bottom"
                          style={{ backgroundColor: "#000000" }}
                        >
                          <a
                            key={viewer.viewersId}
                            href={`/chat/${viewer.viewersId}/${viewer.job_id}`}
                            className="d-flex justify-content-between"
                          >
                            <div className="d-flex flex-row">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/files/${viewer.profilePicture}`}
                                alt="avatar"
                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                width="60"
                                height="60"
                              />
                              <div
                                className="pt-1"
                                style={{ textAlign: "left" }}
                              >
                                <p
                                  className="fw-bold mb-0"
                                  style={{ color: "#ff4e00" }}
                                >
                                  {viewer.firstname} {viewer.lastname} <span style={{color:"white",fontSize:"12px",marginLeft:"7px"}}>(Ref No: {viewer.job_id+1000})</span>
                                </p>
                                <p className="small text-muted">
                                  {viewer.message}
                                </p>
                              </div>
                            </div>
                          </a>
                        </li>
                      );
                    })}
                
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllChat;
