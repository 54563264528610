import React, { useEffect, useState } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBCardImage,
    MDBBtn,
  } from 'mdb-react-ui-kit';
import Lognavbar from '../Components/lognavbar';
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
} from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Footer from './footer';

export default function JobAssigned() {
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
      if (value === basicActive) {
        return;
      }
  
      setBasicActive(value);
    };

  useEffect(()=>{
    document.body.style.backgroundColor="#000000";
  },[])

  return (
    <div className='about'>
        <Lognavbar />
        <div className='head'>
            <h1 className='mainheading' style={{color:"#ff4e00",marginTop:"2%"}}>Assigned Jobs</h1>
            <MDBTabs className='mb-3'>
                <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                    Assigned
                </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                    Rejected
                </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>
            <center>
            <MDBRow className='row-cols-1 row-cols-md-4 g-4' style={{margin:"30px"}}>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardImage src='https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg' position='top' alt='...' />
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Job Name</MDBCardTitle>
                        <p style={{color:"white"}}>Job Description</p>
                        <MDBCardText>
                        <Link to="/job-details"><MDBBtn style={{color:"white",backgroundColor:"#ff4e00"}}>Details</MDBBtn></Link>
                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardImage src='https://img.freepik.com/premium-photo/property-management-real-estate-business-housing_777271-2568.jpg' position='top' alt='...' />
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Job Name</MDBCardTitle>
                        <p style={{color:"white"}}>Job Description</p>
                        <MDBCardText>
                        <MDBBtn style={{color:"white",backgroundColor:"#ff4e00"}}>Details</MDBBtn>
                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardImage src='https://img.freepik.com/free-photo/3d-house-model-with-modern-architecture_23-2151004042.jpg' position='top' alt='...' />
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Job Name</MDBCardTitle>
                        <p style={{color:"white"}}>Job Description</p>
                        <MDBCardText>
                        <MDBBtn style={{color:"white",backgroundColor:"#ff4e00"}}>Details</MDBBtn>
                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardImage src='https://us.123rf.com/450wm/visoot/visoot2307/visoot230702282/208785531-the-concept-of-real-estate-market-growth-rising-interest-rates-for-home-home-loans.jpg?ver=6' position='top' alt='...' />
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Job Name</MDBCardTitle>
                        <p style={{color:"white"}}>Job Description</p>
                        <MDBCardText>
                        <MDBBtn style={{color:"white",backgroundColor:"#ff4e00"}}>Details</MDBBtn>
                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                </MDBRow>
            </center>

        <div style={{textAlign:"left",marginLeft:"60px",marginTop:"20px"}}>
          <h4 style={{color:"white"}}>Job History</h4>
          <MDBTable style={{width:"95%"}}>
              <MDBTableHead style={{backgroundColor:"#1a2229",color:"#ff4e00"}}>
                <tr>
                  <th scope='col'>Sr</th>
                  <th scope='col'>Job Name</th>
                  <th scope='col'>Client Name</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Rating</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody style={{color:"white"}}>
                <tr>
                  <th scope='row'>1</th>
                  <td>ABC</td>
                  <td>ABC</td>
                  <td>Done</td>
                  <td>5</td>
                </tr>
                <tr>
                  <th scope='row'>2</th>
                  <td>ABC</td>
                  <td>ABC</td>
                  <td>Pending</td>
                  <td>5</td>
                </tr>
              </MDBTableBody>
          </MDBTable>
        </div>
        </div>

        <Footer/>
    </div>
  )
}
