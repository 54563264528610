import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import axios from 'axios';
import { useEffect } from 'react';

const ResetPassword = () => {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);


  const {token}=useParams();

  console.log(token);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Admin/UpdatePasswordseeker`, { token, newPassword });
      alert('Password has been reset');
      history.push('/');
    } catch (error) {
      console.error('Error:', error.response.data.message);


      alert(error.response.data.message);
      
    }
    setLoading(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
  }, []);
  

  return (
    <MDBContainer className="mt-5">

<img className=" logoforgot" src="/Assets/logo.png" alt="" />

      <MDBRow className="justify-content-center">
        <MDBCol md="6 mt-5">
          <form onSubmit={handleSubmit}>
           
            <MDBInput
              type="password"
              label="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="mb-4"
            />
            <div className="d-grid">
              <MDBBtn
              
              color="white"
              style={{
                outline: "0",
                borderRadius: "0",
                border: 0,
                background: "transparent",
                color: "#FF4E00",
              }}
              
              type="submit" disabled={loading}>
                {loading ? 'Resetting...' : 'Reset Password'}
              </MDBBtn>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ResetPassword;
