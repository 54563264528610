import React, { useEffect, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBBtn
  } from 'mdb-react-ui-kit';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBTextArea,
  } from 'mdb-react-ui-kit';
import Lognavbar from '../Components/lognavbar';
import {Link} from 'react-router-dom';
import Footer from './footer';

export default function Details() {
    const [basicModal, setBasicModal] = useState(false);
    const [basicModal1, setBasicModal1] = useState(false);

    const toggleOpen = () => setBasicModal(!basicModal);
    const toggleOpen1 = () => setBasicModal1(!basicModal1);
    const [basicActive, setBasicActive] = useState('tab1');

    const ratingChanged = (newRating) => {
      console.log(newRating);
    };

    useEffect(()=>{
      document.body.style.backgroundColor="#000000";
    },[])

  return (
    <div className='about'>
        <Lognavbar />
        <div className='head'>
            <h1 className='mainheading' style={{color:"#ff4e00",marginTop:"2%"}}>Job Details</h1>
            <center>
            <MDBRow className='row-cols-1 row-cols-md-1 g-4' style={{margin:"30px"}}>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardBody>
                    <MDBRow>
                        <MDBCol md={4}>
                            <img src="https://img.freepik.com/free-photo/3d-house-model-with-modern-architecture_23-2151004042.jpg" alt="" style={{width:"100%"}}/>
                        </MDBCol>
                        <MDBCol md={8}>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Job Name</MDBCardTitle>
                        <p style={{color:"#ff4e00",fontWeight:"bold",fontSize:"19px"}}>$100</p>
                        <p style={{marginTop:"-15px",color:"white",cursor:"pointer"}} onClick={toggleOpen1}>Check Viewer Details</p>
                        <p style={{color:"white",textAlign:'justify'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <MDBCardText>
                            <Link to="/chat"><MDBBtn style={{color:"white",backgroundColor:"#ff4e00",marginRight:"10px"}}>Chat with Viewer</MDBBtn></Link>
                            <MDBBtn style={{color:"white",backgroundColor:"#ff4e00"}} onClick={toggleOpen}>Rate Viewer</MDBBtn>
                        </MDBCardText>
                        </MDBCol>
                    </MDBRow>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                </MDBRow>
            </center>
        </div>

        <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
            <MDBModalDialog >
                <MDBModalContent style={{backgroundColor:"black"}}>
                    <MDBModalHeader>
                    <MDBModalTitle style={{color:"#ff4e00"}}>Rate Viewer</MDBModalTitle>
                    <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                    <div className="mb-4" style={{marginTop:"10px"}}>
                        <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Rating</label>
                        <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={24}
                          activeColor="#ffd700"
                        />
                    </div>
                    <div className="mb-4" style={{marginTop:"10px"}}>
                        <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Review</label>
                        <MDBTextArea wrapperClass='mb-4' rows={4} required />
                    </div>
                    </MDBModalBody>

                    <MDBModalFooter>
                    <MDBBtn color='secondary' onClick={toggleOpen}>
                        Close
                    </MDBBtn>
                    <MDBBtn style={{backgroundColor:"#ff4e00",color:"white"}}>Submit</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>

        <MDBModal open={basicModal1} onClose={() => setBasicModal1(false)} tabIndex='-1'>
            <MDBModalDialog >
                <MDBModalContent style={{backgroundColor:"black"}}>
                    <MDBModalHeader>
                    <MDBModalTitle style={{color:"#ff4e00"}}>Viewer Details</MDBModalTitle>
                    <MDBBtn className='btn-close' color='none' onClick={toggleOpen1}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhxNVkMw_B7AJyhLKpXLvKkPwQoBQ9umT0nd3vy20G6w&s" alt="" style={{width:"100px",height:"100px", borderRadius:"50%"}}/>
                    <MDBRow style={{marginTop:"20px"}}>
                        <MDBCol md={6}>
                            <div>
                                <label htmlFor="" style={{color:"#ff4e00"}}>First Name</label>
                                <p style={{color:"white"}}>Shahbaz</p>
                            </div>
                            <div>
                                <label htmlFor="" style={{color:"#ff4e00"}}>Last Name</label>
                                <p style={{color:"white"}}>Rafique</p>
                            </div>
                            <div>
                                <label htmlFor="" style={{color:"#ff4e00"}}>Email</label>
                                <p style={{color:"white"}}>abc@gmail.com</p>
                            </div>
                            <div>
                                <label htmlFor="" style={{color:"#ff4e00"}}>City</label>
                                <p style={{color:"white"}}>City</p>
                            </div>
                            <div>
                                <label htmlFor="" style={{color:"#ff4e00"}}>Rating</label>
                                <p style={{color:"white"}}>4.78</p>
                            </div>
                        </MDBCol>
                        <MDBCol md={6}>
                            <div>
                                <label htmlFor="" style={{color:"#ff4e00"}}>Gender</label>
                                <p style={{color:"white"}}>Male</p>
                            </div>
                            <div>
                                <label htmlFor="" style={{color:"#ff4e00"}}>Phone No</label>
                                <p style={{color:"white"}}>000000000000</p>
                            </div>
                            <div>
                                <label htmlFor="" style={{color:"#ff4e00"}}>About</label>
                                <p style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>

        <Footer/>
    </div>
  )
}
