import React, { useEffect } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCardImage,
} from "mdb-react-ui-kit";
import Lognavbar from "../Components/lognavbar";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Footer from "./footer";
import { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function AllPackages() {
  const [prices, setallprices] = useState("");
  const [pacakgesdescription, setpackagesdescription] = useState("");

  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/getPrices`
      );
      console.log(response.data);
      setallprices(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchdata1 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/getpackagesdescription`
      );
      console.log("setpackagesdescription", response.data);
      setpackagesdescription(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchdata();
    fetchdata1();
    document.body.style.fontFamily = "bahnschrift";
    document.body.style.backgroundColor = "#000000";
  }, []);
  const basicViewingPrice =
    prices && prices.find((p) => p.PackageName === "Basic Viewing");
  const ProViewing =
    prices && prices.find((p) => p.PackageName === "Pro Viewing");
  const Property =
    prices && prices.find((p) => p.PackageName === "Property Search");

  const [basicViewingDescriptions, setBasicViewingDescriptions] = useState([]);

  useEffect(() => {
    if (pacakgesdescription) {
      const filteredDescriptions = pacakgesdescription.filter(
        (package1) => package1.name === "Basic Viewing"
      );



      console.log(filteredDescriptions);
      setBasicViewingDescriptions(
        filteredDescriptions[0].description.split("\n").filter((point) => point.trim() !== "")
      );
    }
  }, [pacakgesdescription]);


  const [proviewingdescription, setproviewingdescription] = useState([]);

  useEffect(() => {
    if (pacakgesdescription) {
      const filteredDescriptions = pacakgesdescription.filter(
        (package1) => package1.name === "Pro Viewing"
      );



      console.log(filteredDescriptions);
      setproviewingdescription(
        filteredDescriptions[0].description.split("\n").filter((point) => point.trim() !== "")
      );
    }
  }, [pacakgesdescription]);


  const [propertdescription, setpropertdescription] = useState([]);

  useEffect(() => {
    if (pacakgesdescription) {
      const filteredDescriptions = pacakgesdescription.filter(
        (package1) => package1.name === "Property Search"
      );



      console.log(filteredDescriptions);
      setpropertdescription(
        filteredDescriptions[0].description.split("\n").filter((point) => point.trim() !== "")
      );
    }
  }, [pacakgesdescription]);
  return (
    <div className="about">
      <Lognavbar />
      <div className="head">
        <h1
          className="mainheading"
          style={{ color: "#ff4e00", marginTop: "2%" }}
        >
          Packages
        </h1>
        <center>
          <MDBRow className="row-cols-1 row-cols-md-2 g-4 cardss">
            <MDBCol>
              <MDBCard
                className="h-100"
                style={{
                  backgroundColor: "#1a2229",
                  borderRadius: "30px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <MDBCardImage
                  src="../Assets/images/2.png"
                  position="top"
                  alt="..."
                  style={{
                    height: "250px",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                />
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Basic Viewing
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {basicViewingPrice
                      ? `£${basicViewingPrice.Price}`
                      : "Price not available"}
                  </h4>
                  <MDBCardText>
                    <div>
                      {basicViewingDescriptions &&
                        basicViewingDescriptions.map((desc,index) => {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                              {index != 0 && (
                               <div style={{ width: "23px", height: "23px", flexShrink: 0 }}>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color="#ff4e00"
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                </div>
                                )}

                                <p
                                  style={{
                                    marginLeft: "5px",
                                    textAlign: "left",
                                    marginTop: "3px",
                                    color: "white",
                                  }}
                                >
                                  {desc}
                                </p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <Link to={`/subscribe?package=basic${
                        basicViewingPrice
                          ? `&price=${basicViewingPrice.Price}`
                          : ""
                      }`}>
                    <MDBBtn style={{ backgroundColor: "#ff4e00" }}>
                      Subscribe
                    </MDBBtn>
                    </Link>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard
                className="h-100"
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardImage
                  src="../Assets/images/4.png"
                  position="top"
                  alt="..."
                  style={{
                    height: "250px",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                />
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Pro Viewing
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {ProViewing
                      ? `£${ProViewing.Price}`
                      : "Price not available"}
                  </h4>
                  <MDBCardText>
                    <div>
                    {proviewingdescription &&
                        proviewingdescription.map((desc,index) => {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                              {index != 0 && (
                                <div style={{ width: "23px", height: "23px", flexShrink: 0 }}>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color="#ff4e00"
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                </div>
                              )}

                                <p
                                  style={{
                                    marginLeft: "5px",
                                    textAlign: "left",
                                    marginTop: "3px",
                                    color: "white",
                                  }}
                                >
                                  {desc}
                                </p>
                              </div>
                            </>
                          );
                        })}
                    
                    </div>
                  
                  <Link to={`/subscribe?package=pro${
                        ProViewing ? `&price=${ProViewing.Price}` : ""
                      }`}>
                    <MDBBtn style={{ backgroundColor: "#ff4e00" }}>
                      Subscribe
                    </MDBBtn>
                  </Link>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard
                className="h-100"
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardImage
                  src="../Assets/images/property.png"
                  position="top"
                  alt="..."
                  style={{
                    height: "250px",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                />
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Property Search
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {Property ? `£${Property.Price}` : "Price not available"}
                  </h4>
                  <MDBCardText>
                    <div>
                    {propertdescription &&
                      propertdescription.map((desc, index) => {
                        return (
                          <div key={index} style={{ display: "flex" }}>
                            {index != 0 && (
                              <div style={{ width: "23px", height: "23px", flexShrink: 0 }}>
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  color="#ff4e00"
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </div>
                            )}
                            <p
                              style={{
                                marginLeft: "5px",
                                textAlign: "left",
                                marginTop: "3px",
                                color: "white",
                              }}
                            >
                              {desc}
                            </p>
                          </div>
                        );
                      })}                
                    </div>
                  
                  <Link to={`/property-search?${
                        Property ? `price=${Property.Price}` : ""
                      }`}>
                    <MDBBtn style={{ backgroundColor: "#ff4e00" }}>
                      Subscribe
                    </MDBBtn>
                    </Link>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>
      </div>

      <Footer />
    </div>
  );
}
