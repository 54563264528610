import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import ReactStars from "react-rating-stars-component";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
} from "mdb-react-ui-kit";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
} from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import Lognavbar from "../Components/lognavbar";
import Footer from "./footer";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import axios from "axios";
import Auth from "./auth";
import { Link } from "react-router-dom";
export default function Home() {
  const [basicModal, setBasicModal] = useState(false);
  const [selectedjob, setselectedjob] = useState();

  const toggleOpen = (jobs) => {
    setselectedjob(jobs);

    setBasicModal(!basicModal);
  };

  const [basicModal1, setBasicModal1] = useState(false);
  const toggleOpen1 = () => setBasicModal(!basicModal1);

  const [basicModal2, setBasicModal2] = useState(false);

  const [currentjobs, setcurrentjobs] = useState(false);
  const [userdata, setuserdata] = useState();
  const [isReview,setIsreview]=useState(0);

  const toggleOpen2 = () => setBasicModal(!basicModal2);
  const [ratings, setratings] = useState();
  const [completedjobs, setcompletedjobs] = useState(false);
  const [viewersmessages, setallviewersmessages] = useState("");
  const [completedJobID,setcompletedjobId]=useState();

  const ratingChanged = (newRating) => {
    setratings(newRating);
  };

  useEffect(() => {
    const seekersId = Cookies.get("S_Id");
    const fetchjobs = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/asare/packages/Allseekersjobs`,
          {
            seekersId: seekersId,
          }
        );
        setcurrentjobs(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchcompletedjobs = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/asare/packages/Allseekersjobscompleted`,
          {
            seekersId: seekersId,
          }
        );

        console.log("completed", response.data);
        setcompletedjobs(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchjobs();
    fetchcompletedjobs();
  }, []);

  useEffect(() => {
    GetChatAllViewers();
    document.body.style.backgroundColor = "#000000";
    const queryParams = new URLSearchParams(window.location.search);
    const client_e = queryParams.get("seekers_e");
    const client_d = queryParams.get("seekers_d");
    const client_Id = queryParams.get("seekers_Id");
    console.log(client_Id, client_d, client_e);
    if (client_e) {
      Cookies.set("S_email", atob(client_e), { expires: 1 });
    }
    if (client_d) {
      Cookies.set("S_Id", atob(client_Id), { expires: 1 });
    }
    if (client_Id) {
      Cookies.set("S_Picture", atob(client_d), { expires: 1 });
      window.location.href = "/";
    }
  }, []);

  const fetchviewerdetails = async () => {
    setBasicModal2(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/profile/get`,
        {
          params: {
            Id: selectedjob.viewersId,
          },
        }
      );

      console.log(response.data.data[0]);

      setuserdata(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const [review, setReview] = useState("");

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const submitreview = async () => {
    setIsreview(1);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/main/ratingviewer`,
        {
          Id: completedJobID.Id,
          ratings: ratings,
          review: review,
        }
      );
      setBasicModal1(!basicModal1);
      handleComplete();
    } catch (error) {
      console.log(error);
    }
  };

  async function GetChatAllViewers() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat/GetChatAllViewers`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            seekersId: Cookies.get("S_Id"),
          },
        }
      );
      const responseData = response.data.data;

      setallviewersmessages(response.data.data);

      console.log(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleComplete = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/main/complete`,
        { job_id: completedJobID.Id, viewersId: completedJobID.viewersId, SeekerEmail: Cookies.get('S_email'), seekerFirstName: Cookies.get('S_name') }
      );
      console.log(response);
      if(response.data.message=="success"){
        window.location.href="/home";
      }
    } catch (err) {
      console.error("Error fetching assigned jobs:", err);
    }
    setBasicModal(false);
  };

  function convertToUKTime(dateString) {
    const date = new Date(dateString);
  
    const options = {
      timeZone: 'Europe/London',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
  
    const ukTimeString = date.toLocaleString('en-GB', options);
  
    return ukTimeString;
  }

  return (
    <div>
      <Auth />
      <Lognavbar />
      <div>
        <h1
          className="mainheading"
          style={{ color: "#ff4e00", marginTop: "2%" }}
        >
          SEEKERS PORTAL
        </h1>
        <p style={{ color: "white" }}>Welcome Back to Seekers Dashboard</p>

        <div className="decmargin" style={{ textAlign: "left", marginLeft: "60px" }}></div>

        <div className="decmargin" 
          style={{ textAlign: "left", marginLeft: "60px", marginTop: "30px" }}
        >
          <h4 style={{ color: "white" }}>Current Jobs</h4>
          <center>
            <MDBRow
              className="row-cols-1 row-cols-md-4 g-4 currentmargin"
              style={{ margin: "30px" }}
            >
              {currentjobs &&
                currentjobs.map((currentjob) => {
                  return (
                    <MDBCol>
                      <MDBCard
                        className="h-100"
                        style={{ backgroundColor: "#1a2229" }}
                      >
                        <MDBCardImage
                          style={{ height: "160px" }}
                          src={
                            currentjob.packagename === "Basic Viewing"
                              ? "Assets/2.png"
                              : currentjob.packagename === "Pro Viewing"
                              ? "Assets/4.png"
                              : "Assets/property.png"
                          }
                          position="top"
                          alt="..."
                        />

                        <MDBCardBody>
                          <MDBCardTitle
                            style={{ marginTop: "25px", color: "white" }}
                          >
                            {currentjob.packagename}
                          </MDBCardTitle>

                          <p style={{color:'white'}}><span style={{color:"#ff4e00"}}>Ref No: </span> {currentjob.Id+1000}</p>

                          <MDBCardText>
                            <MDBBtn
                              style={{
                                color: "white",
                                backgroundColor: "#ff4e00",
                                marginRight:"5px"
                              }}
                              onClick={() => {
                                toggleOpen(currentjob);
                              }}
                            >
                              Details
                            </MDBBtn>
                            
                            {currentjob.PackageApproved?(
                              <MDBBtn
                              className="mt-2"
                              style={{
                                color: "white",
                                backgroundColor: "#ff4e00",
                              }}
                              onClick={() => {
                                setBasicModal1(true);
                                setcompletedjobId(currentjob);
                              }}
                            >
                              Mark Job As Completed
                             </MDBBtn>
                            ):( 
                              ""
                            )}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  );
                })}
            </MDBRow>
          </center>
        </div>

        <div
        className="decmargin"
          style={{ textAlign: "left", marginLeft: "60px", marginTop: "20px" }}
        >
          <h4 style={{ color: "white" }}>Job History</h4>
          <MDBTable responsive style={{ width: "95%" }}>
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th scope="col">Sr</th>
                <th scope="col">Job Name</th>
                <th scope="col">Viewer Name</th>
                <th scope="col">Rating</th>
                <th scope="col">Dated</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody style={{ color: "white" }}>
              {completedjobs &&
                completedjobs.map((viewer, index) => {
                  return (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{viewer.packagename}</td>
                      <td>{viewer.firstname + " " + viewer.lastname}</td>
                      <td>
                        {viewer.jobratings > 0
                          ? Array.from({ length: viewer.jobratings }).map(
                              (_, index) => (
                                <MDBIcon
                                  key={index}
                                  style={{ color: "#ff4e00" }}
                                  icon="star"
                                />
                              )
                            )
                          : "N/A"}
                      </td>{" "}
                      <td>{convertToUKTime(viewer.completedAt)}</td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </div>

        <div className="decmargin mt-3"  style={{ textAlign: "left", marginLeft: "60px" }}>
          <h4 style={{ color: "white" }}>Chat</h4>
          <div className="container py-5">
            <div className="row">
              <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
                <h5
                  className="font-weight-bold mb-3 text-center text-lg-start"
                  style={{ color: "white" }}
                >
                  Contacts
                </h5>
                <div className="card" style={{ backgroundColor: "#1a2229" }}>
                  <div className="card-body">
                    <ul className="list-unstyled mb-0">
                      {viewersmessages &&
                        viewersmessages.map((viewer, index) => {
                          return (
                            <li
                              className="p-2 border-bottom"
                              style={{ backgroundColor: "#000000" }}
                            >
                              <a
                                key={viewer.viewersId}
                                href={`/chat/${viewer.viewersId}/${viewer.job_id}`}
                                className="d-flex justify-content-between"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}/files/${viewer.profilePicture}`}
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width="60"
                                    height="60"
                                  />
                                  <div
                                    className="pt-1"
                                    style={{ textAlign: "left" }}
                                  >
                                    <p
                                      className="fw-bold mb-0"
                                      style={{ color: "#ff4e00" }}
                                    >
                                      {viewer.firstname} 
                                      {viewer.lastname} <span style={{color:"white",fontSize:"12px",marginLeft:"7px"}}>(Ref No: {viewer.job_id+1000})</span>
                                    </p>
                                    <p className="small text-muted">
                                      {viewer.message}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MDBModal
        open={basicModal}
        onClose={() => setBasicModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#ff4e00" }}>
                Job Details
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            {/* <MDBModalBody>
              <MDBRow>
                <MDBCol md={4}>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/files/${
                      selectedjob && selectedjob.job_image
                    }`}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </MDBCol>
                <MDBCol md={8}>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    {selectedjob && selectedjob.job_name}
                  </MDBCardTitle>
                  <MDBBtn
                    style={{
                      color: "white",
                      backgroundColor: "#ff4e00",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      fetchviewerdetails();
                      toggleOpen();
                    }}
                  >
                    Check Viewer Details
                  </MDBBtn>
                  <p
                    style={{
                      color: "#ff4e00",
                      fontWeight: "bold",
                      fontSize: "19px",
                    }}
                  >
                    ${selectedjob && selectedjob.job_price}
                  </p>
                  <p style={{ color: "white", textAlign: "justify" }}>
                    {selectedjob && selectedjob.job_detail}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBModalBody> */}

            <MDBModalBody>
              <MDBRow>
                <MDBCol md={12}>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    {selectedjob && selectedjob.packagename}
                  </MDBCardTitle>

                  {selectedjob && selectedjob.PackageApproved=="1"?(
                    <MDBBtn
                    style={{
                      color: "white",
                      backgroundColor: "#ff4e00",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      fetchviewerdetails();
                      toggleOpen();
                    }}
                  >
                    Check Viewer Details
                  </MDBBtn>
                  ):(
                    <p style={{color:"#ff4e00"}}>Job not assigned yet</p>
                  )}
                  <p
                    style={{
                      color: "#ff4e00",
                      fontWeight: "bold",
                      fontSize: "19px",
                    }}
                  >
                    $
                    {selectedjob && selectedjob.packagename
                      ? selectedjob.packagename === "Basic Viewing"
                        ? "100"
                        : selectedjob.packagename === "Pro Viewing"
                        ? "200"
                        : "200"
                      : "Package name not available"}
                  </p>

                  {selectedjob &&
                    selectedjob.packagename === "Basic Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          The property can be found here,{" "}
                          <strong>
                          {selectedjob.Q1URL ? (
                              <a
                                href={selectedjob.Q1URL}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#ff4e00" }}
                              >
                                {selectedjob.Q1URL}
                              </a>
                            ) : selectedjob.street1 ? (
                              `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                            ) : (
                              "N/A"
                            )}
                          </strong>
                          . They{" "}
                          <strong>
                            {selectedjob.Q3
                              ? selectedjob.Q3 === "yes"
                                ? "have"
                                : "have not"
                              : "N/A"}
                          </strong>{" "}
                          communicated with agent/property management. We can
                          reach them on{" "}
                          <strong>
                            {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                          </strong>
                          , and what they said was{" "}
                          <strong>
                            {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                          </strong>
                          . They are{" "}
                          <strong>
                            {selectedjob.Q4
                              ? selectedjob.Q4 === "yes"
                                ? "okay"
                                : "not okay"
                              : "N/A"}
                          </strong>{" "}
                          with 10 working days to get viewing done.{" "}
                          {selectedjob.Q4 === "no" ? (
                            <span>
                              They want it done in{" "}
                              <strong>{selectedjob.Q4Date}</strong>.
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p>
                          They want to be communicated via{" "}
                          <strong>
                            {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedjob.Q5 === "email" ? (
                            <strong> on {selectedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                          . The promo code used is{" "}
                          <strong>
                            {selectedjob.promo ? selectedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}

                  {selectedjob && selectedjob.packagename === "Pro Viewing" && (
                    <div className="job-details text-white">
                      <p>
                        The property can be found here,{" "}
                        <strong>
                          {selectedjob.Q1URL
                            ? selectedjob.Q1URL
                            : selectedjob.street1
                            ? `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                            : "N/A"}
                        </strong>{" "}
                        They{" "}
                        <strong>
                          {selectedjob.Q2
                            ? selectedjob.Q2 === "yes"
                              ? "have specific concerns about the property"
                              : "have not specific concerns about the property"
                            : "N/A"}
                        </strong>{" "}
                        They are concerned about
                        <strong>
                          {selectedjob.Q2 === "yes"
                            ? `  ${selectedjob.Q2specify}`
                            : ""}
                        </strong>{" "}
                        They
                        <strong>
                          {selectedjob.Q3
                            ? selectedjob.Q3 === "yes"
                              ? "  have"
                              : "  have not"
                            : "N/A"}
                        </strong>{" "}
                        communicated with agent/property management. We can
                        reach them on{" "}
                        <strong>
                          {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                        </strong>
                        , and what they said was{" "}
                        <strong>
                          {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                        </strong>
                        . They are{" "}
                        <strong>
                          {selectedjob.Q4
                            ? selectedjob.Q4 === "yes"
                              ? "okay"
                              : "not okay"
                            : "N/A"}
                        </strong>{" "}
                        with 10 working days to get viewing done.{" "}
                        {selectedjob.Q4 === "no" ? (
                          <span>
                            They want it done in{" "}
                            <strong>{selectedjob.Q4Date}</strong>.
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <p>
                        They want to be communicated via{" "}
                        <strong>
                          {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                        </strong>{" "}
                        {selectedjob.Q5 === "email" ? (
                          <strong>on {selectedjob.Q5text}</strong>
                        ) : (
                          ""
                        )}
                        . The promo code used is{" "}
                        <strong>
                          {selectedjob.promo ? selectedjob.promo : "none"}
                        </strong>
                        .
                      </p>
                    </div>
                  )}

                  {selectedjob &&
                    selectedjob.packagename !== "Basic Viewing" &&
                    selectedjob.packagename !== "Pro Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          They plan on living at{" "}
                          <strong>
                            {selectedjob.Q1 ? selectedjob.Q1 : "N/A"}
                          </strong>
                          . They plan on{" "}
                          <strong>
                            {selectedjob.Q6 ? selectedjob.Q6 : "N/A"}
                          </strong>
                          .
                        </p>

                        {selectedjob.Q6 === "Renting" && (
                          <p>
                            If it's renting; Their allocated budget for this
                            property is{" "}
                            <strong>
                              {selectedjob.Q2 ? selectedjob.Q2 : "N/A"}
                            </strong>
                            . They are looking to rent for{" "}
                            <strong>
                              {selectedjob.Q3 ? selectedjob.Q3 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        {selectedjob.Q6 === "Buying" && (
                          <p>
                            They want a property within the price range of{" "}
                            <strong>
                              {" "}
                              £{selectedjob.Q7 ? selectedjob.Q7 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        <p>
                          They are looking for a property with these
                          preferences:{" "}
                          <strong>
                            {selectedjob.Q4 ? selectedjob.Q4 : "N/A"}
                          </strong>
                          . They want to be communicated via{" "}
                          <strong>
                            {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedjob.Q5 === "Email" ? (
                            <strong>on {selectedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                        </p>

                        <p>
                          The promo code used is{" "}
                          <strong>
                            {selectedjob.promo ? selectedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}
               
                </MDBCol>
              </MDBRow>
            </MDBModalBody>

            {selectedjob && selectedjob.PackageApproved=="1"?(
            <MDBModalFooter>
              <Link to={`/chat/${selectedjob && selectedjob.viewersId}/${selectedjob && selectedjob.Id}`}>
                <MDBBtn style={{ color: "white", backgroundColor: "#ff4e00" }}>
                  Chat
                </MDBBtn>
              </Link>

                {/* <MDBBtn
                  style={{
                    color: "white",
                    backgroundColor: "#ff4e00",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setBasicModal1(true);
                    setBasicModal(!basicModal);
                  }}
                >
                  Rate Viewer
                </MDBBtn> */}
            </MDBModalFooter>
              ):(
                ""
            )}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        open={basicModal1}
        onClose={() => setBasicModal1(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#ff4e00" }}>
                Rate Viewer
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen1}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="mb-4" style={{ marginTop: "10px" }}>
                <label
                  htmlFor="form1"
                  style={{
                    textAlign: "left",
                    display: "block",
                    color: "white",
                  }}
                >
                  Rating
                </label>
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={24}
                  activeColor="#ffd700"
                />
              </div>
              <div className="mb-4" style={{ marginTop: "10px" }}>
                <label
                  htmlFor="form1"
                  style={{
                    textAlign: "left",
                    display: "block",
                    color: "white",
                  }}
                >
                  Review
                </label>
                <MDBTextArea
                  onChange={handleReviewChange}
                  wrapperClass="mb-4"
                  rows={4}
                  name="review"
                  required
                />
              </div>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setBasicModal1(!basicModal1);
                }}
              >
                Close
              </MDBBtn>
              <MDBBtn
                onClick={submitreview}
                style={{ backgroundColor: "#ff4e00", color: "white" }}
              >
                Submit
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        open={basicModal2}
        onClose={() => setBasicModal2(false)}
        tabIndex="-1"
      >
        <MDBModalDialog centered>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#ff4e00" }}>
                Viewer Details
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen2}
              ></MDBBtn>
            </MDBModalHeader>

            <MDBModalBody>
              {/* <img
                src={`${process.env.REACT_APP_BASE_URL}/files/${
                  userdata && userdata.profilePicture
                }`}
                alt=""
                style={{ width: "100px", height: "100px", borderRadius: "50%" }}
              /> */}
              <MDBRow style={{ marginTop: "20px" }}>
                <MDBCol md={12}>  
                  <div>
                    <label htmlFor="" style={{ color: "#ff4e00" }}>
                      Email
                    </label>
                    <p style={{ color: "white" }}>
                      {userdata && userdata.email}
                    </p>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md={6}>
                  <div>
                    <label htmlFor="" style={{ color: "#ff4e00" }}>
                      Gender
                    </label>
                    <p style={{ color: "white" }}>
                      {userdata && userdata.gender}
                    </p>
                  </div>
                </MDBCol>
                <MDBCol md={6}>
                    <div>
                      <label htmlFor="" style={{ color: "#ff4e00" }}>
                        Rating
                      </label>
                      <p style={{ color: "white" }}>
                        {userdata && userdata.rating}
                      </p>
                    </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                  <MDBCol md={12}>
                    <div>
                      <label htmlFor="" style={{ color: "#ff4e00" }}>
                        About
                      </label>
                      <p style={{ color: "white" }}>
                        {userdata && userdata.about}
                      </p>
                    </div>
                  </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <Footer />
    </div>
  );
}
