import React, { useEffect } from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBIcon,MDBBtn } from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';

export default function Cancel() {
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <MDBCard style={{ maxWidth: '30rem',width:"400px", textAlign: 'center',backgroundColor:"#1a2229" }}>
        <MDBCardBody>
          <MDBCardTitle>
            <img src="https://cdn-icons-png.freepik.com/256/11695/11695444.png?semt=ais_hybrid" alt="" style={{width:"70px"}}/>
          </MDBCardTitle>
          <h3 style={{color:"#ff4e00",marginTop:"10px"}}>Error</h3>
          <MDBCardText style={{color:"white"}}>Error while making payment</MDBCardText>
          <Link to="/home"><MDBBtn style={{backgroundColor:"#ff4e00"}}>Back to Home</MDBBtn></Link>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
}
