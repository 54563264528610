import React, { useEffect } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
  } from 'mdb-react-ui-kit';
import Lognavbar from '../Components/lognavbar';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Footer from './footer';

export default function History() {

  useEffect(()=>{
    document.body.style.backgroundColor="#000000";
  },[])

  return (
    <div className='about'>
        <Lognavbar />
        <div className='head'>
            <h1 className='mainheading' style={{color:"#ff4e00",marginTop:"2%"}}>Job History</h1>
            <center>
            <MDBRow className='row-cols-1 row-cols-md-4 g-4' style={{width:"80%"}}>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Total Jobs</MDBCardTitle>
                        <h4 style={{color:"#ff4e00"}}>100</h4>
                        <MDBCardText>
          
                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Jobs Done</MDBCardTitle>
                        <h4 style={{color:"#ff4e00"}}>40</h4>
                        <MDBCardText>


                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Pending Jobs</MDBCardTitle>
                        <h4 style={{color:"#ff4e00"}}>60</h4>
                        <MDBCardText>


                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Overall Rating</MDBCardTitle>
                        <h4 style={{color:"#ff4e00"}}>4.89</h4>
                        <MDBCardText>


                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                </MDBRow>
            </center>

          <div style={{marginTop:"5%"}}>
            <MDBTable>
              <MDBTableHead style={{backgroundColor:"#1a2229",color:"#ff4e00"}}>
                <tr>
                  <th scope='col'>Sr</th>
                  <th scope='col'>Job Name</th>
                  <th scope='col'>Client Name</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Rating</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody style={{color:"white"}}>
                <tr>
                  <th scope='row'>1</th>
                  <td>ABC</td>
                  <td>ABC</td>
                  <td>Done</td>
                  <td>5</td>
                </tr>
                <tr>
                  <th scope='row'>2</th>
                  <td>ABC</td>
                  <td>ABC</td>
                  <td>Pending</td>
                  <td>5</td>
                </tr>
                <tr>
                  <th scope='row'>3</th>
                  <td>ABC</td>
                  <td>ABC</td>
                  <td>Done</td>
                  <td>5</td>
                </tr>
              </MDBTableBody>
          </MDBTable>
          </div>
        </div>

        <Footer/>
    </div>
  )
}
